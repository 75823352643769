import {
  categoriasList,
  dashboardLinksAdmin,
  dashboardLinksCapitan,
  dashboardLinksJugador,
  dashboardLinksStaff,
  rolesList,
} from "./constants";
import { categoriesPlayer } from "./dbConstants";
import {
  dashboardImagesListAdmin,
  dashboardImagesListCapitan,
  dashboardImagesListJugador,
  dashboardImagesListStaff,
} from "./imagesList";

export const convertDate = (inputDate, format = "EN") => {
  const fechaDB = new Date(inputDate);
  const dia = fechaDB.getDate().toString().padStart(2, "0");
  const mes = (fechaDB.getMonth() + 1).toString().padStart(2, "0");
  const año = fechaDB.getFullYear().toString();
  const fechaFormateada =
    format === "EN" ? `${año}-${mes}-${dia}` : `${dia}-${mes}-${año}`;
  return fechaFormateada;
};

export const getRoleInfo = (roleId) => {
  const roleFound = rolesList.find((item) => item.key === roleId);
  return roleFound ? roleFound.text : "";
};

export const getCategoryInfo = (cateId) => {
  const catFound = categoriasList.find((item) => item.key === cateId);
  return catFound ? catFound.text : "";
};

export const getPlayerCategoryInfo = (cateId) => {
  const catFound = categoriesPlayer.find((item) => item.key === cateId);
  return catFound ? catFound.text : "";
};

export const getDashboardListByRole = (role) => {
  switch (role) {
    case "super-admin":
      return dashboardLinksAdmin;
    case "admin":
      return dashboardLinksAdmin;
    case "capitan":
      return dashboardLinksCapitan;
    case "sub-capitan":
      return dashboardLinksCapitan;
    case "delegado":
      return dashboardLinksJugador;
    case "staff":
      return dashboardLinksStaff;
    case "jugador":
      return dashboardLinksJugador;
    default:
      return dashboardLinksJugador;
  }
};

export const getDashboardImagesdListByRole = (role) => {
  switch (role) {
    case "super-admin":
      return dashboardImagesListAdmin;
    case "admin":
      return dashboardImagesListAdmin;
    case "capitan":
      return dashboardImagesListCapitan;
    case "sub-capitan":
      return dashboardImagesListCapitan;
    case "delegado":
      return dashboardImagesListJugador;
    case "staff":
      return dashboardImagesListStaff;
    case "jugador":
      return dashboardImagesListJugador;
    default:
      return dashboardImagesListJugador;
  }
};

export const generateUserIdentificator = (cedula, id) => {
  if (cedula && id) return `TOL${cedula.slice(-3)}-${id}`;
  return "";
};

export function getDayOfWeek(date) {
  if (date) {
    const parts = date.split("-");
    const myDate = new Date(parts[0], parts[1] - 1, parts[2]);
    const day = myDate.getDay();
    const daysOfWeek = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
    ];
    const dayName = daysOfWeek[day];
    return dayName;
  }
  return;
}

export const handleResults = (results) => {
  const resultsDoble1 = `${results.result_111 || 0} - ${
    results.result_112 || 0
  }, ${results.result_121 || 0} - ${results.result_122 || 0}, ${
    results.result_131 || 0
  } - ${results.result_132 || 0}`;
  const resultsDoble2 = `${results.result_211 || 0} - ${
    results.result_212 || 0
  }, ${results.result_221 || 0} - ${results.result_222 || 0}, ${
    results.result_231 || 0
  } - ${results.result_232 || 0}`;
  const resultsDoble3 = `${results.result_311 || 0} - ${
    results.result_312 || 0
  }, ${results.result_321 || 0} - ${results.result_322 || 0}, ${
    results.result_331 || 0
  } - ${results.result_332 || 0}`;
  const resultsDoble4 = `${results.result_411 || 0} - ${
    results.result_412 || 0
  }, ${results.result_421 || 0} - ${results.result_422 || 0}, ${
    results.result_431 || 0
  } - ${results.result_432 || 0}`;
  const resultsDoble5 = `${results.result_511 || 0} - ${
    results.result_512 || 0
  }, ${results.result_521 || 0} - ${results.result_522 || 0}, ${
    results.result_531 || 0
  } - ${results.result_532 || 0}`;
  const resultados = {
    doble_1: resultsDoble1,
    doble_2: resultsDoble2,
    doble_3: resultsDoble3,
    doble_4: resultsDoble4,
    doble_5: resultsDoble5,
  };
  return resultados;
};

export const handleResultsFromDb = (results) => {
  if (results.length > 0) {
    const doble1 = results[0] && results[0].split(",");
    const doble2 = results[1] && results[1].split(",");
    const doble3 = results[2] && results[2].split(",");
    const doble4 = results[3] && results[3].split(",");
    const doble5 = results[4] && results[4].split(",");
    const result_111 =
      (doble1 && doble1[0] && doble1[0].split("-")[0].trim()) || 0;
    const result_112 =
      (doble1 && doble1[0] && doble1[0].split("-")[1].trim()) || 0;
    const result_121 =
      (doble1 && doble1[1] && doble1[1].split("-")[0].trim()) || 0;
    const result_122 =
      (doble1 && doble1[1] && doble1[1].split("-")[1].trim()) || 0;
    const result_131 =
      (doble1 && doble1[2] && doble1[2].split("-")[0].trim()) || 0;
    const result_132 =
      (doble1 && doble1[2] && doble1[2].split("-")[1].trim()) || 0;

    const result_211 =
      (doble2 && doble2[0] && doble2[0].split("-")[0].trim()) || 0;
    const result_212 =
      (doble2 && doble2[0] && doble2[0].split("-")[1].trim()) || 0;
    const result_221 =
      (doble2 && doble2[1] && doble2[1].split("-")[0].trim()) || 0;
    const result_222 =
      (doble2 && doble2[1] && doble2[1].split("-")[1].trim()) || 0;
    const result_231 =
      (doble2 && doble2[2] && doble2[2].split("-")[0].trim()) || 0;
    const result_232 =
      (doble2 && doble2[2] && doble2[2].split("-")[1].trim()) || 0;

    const result_311 =
      (doble3 && doble3[0] && doble3[0].split("-")[0].trim()) || 0;
    const result_312 =
      (doble3 && doble3[0] && doble3[0].split("-")[1].trim()) || 0;
    const result_321 =
      (doble3 && doble3[1] && doble3[1].split("-")[0].trim()) || 0;
    const result_322 =
      (doble3 && doble3[1] && doble3[1].split("-")[1].trim()) || 0;
    const result_331 =
      (doble3 && doble3[2] && doble3[2].split("-")[0].trim()) || 0;
    const result_332 =
      (doble3 && doble3[2] && doble3[2].split("-")[1].trim()) || 0;

    const result_411 =
      (doble4 && doble4[0] && doble4[0].split("-")[0].trim()) || 0;
    const result_412 =
      (doble4 && doble4[0] && doble4[0].split("-")[1].trim()) || 0;
    const result_421 =
      (doble4 && doble4[1] && doble4[1].split("-")[0].trim()) || 0;
    const result_422 =
      (doble4 && doble4[1] && doble4[1].split("-")[1].trim()) || 0;
    const result_431 =
      (doble4 && doble4[2] && doble4[2].split("-")[0].trim()) || 0;
    const result_432 =
      (doble4 && doble4[2] && doble4[2].split("-")[1].trim()) || 0;

    const result_511 =
      (doble5 && doble5[0] && doble5[0].split("-")[0].trim()) || 0;
    const result_512 =
      (doble5 && doble5[0] && doble5[0].split("-")[1].trim()) || 0;
    const result_521 =
      (doble5 && doble5[1] && doble5[1].split("-")[0].trim()) || 0;
    const result_522 =
      (doble5 && doble5[1] && doble5[1].split("-")[1].trim()) || 0;
    const result_531 =
      (doble5 && doble5[2] && doble5[2].split("-")[0].trim()) || 0;
    const result_532 =
      (doble5 && doble5[2] && doble5[2].split("-")[1].trim()) || 0;
    const finalResult = {
      result_111: result_111,
      result_112: result_112,
      result_121: result_121,
      result_122: result_122,
      result_131: result_131,
      result_132: result_132,
      result_211: result_211,
      result_212: result_212,
      result_221: result_221,
      result_222: result_222,
      result_231: result_231,
      result_232: result_232,
      result_311: result_311,
      result_312: result_312,
      result_321: result_321,
      result_322: result_322,
      result_331: result_331,
      result_332: result_332,
      result_411: result_411,
      result_412: result_412,
      result_421: result_421,
      result_422: result_422,
      result_431: result_431,
      result_432: result_432,
      result_511: result_511,
      result_512: result_512,
      result_521: result_521,
      result_522: result_522,
      result_531: result_531,
      result_532: result_532,
    };
    return finalResult;
  }
  return;
};

const determinePoints = (winner) => {
  switch (winner) {
    case "ganadordoble1":
      return 2;
    case "ganadordoble2":
      return 1;
    case "ganadordoble3":
      return 1;
    case "ganadordoble4":
      return 2;
    case "ganadordoble5":
      return 3;
    default:
      console.log(`Sorry, we are out of ${winner}.`);
  }
};

const determinePlayersPoints = (winner, team, walkover, idcategory) => {
  switch (winner) {
    case "ganadordoble1":
      return [
        {
          cedula: team.doble_11,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
        {
          cedula: team.doble_12,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
      ];
    case "ganadordoble2":
      return [
        {
          cedula: team.doble_21,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
        {
          cedula: team.doble_22,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
      ];
    case "ganadordoble3":
      return [
        {
          cedula: team.doble_31,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
        {
          cedula: team.doble_32,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
      ];
    case "ganadordoble4":
      return [
        {
          cedula: team.doble_41,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
        {
          cedula: team.doble_42,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
      ];
    case "ganadordoble5":
      return [
        {
          cedula: team.doble_51,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
        {
          cedula: team.doble_52,
          puntos: walkover?.includes(winner) ? 10 : 20,
          idcategory: idcategory,
        },
      ];
    default:
      console.log(`Sorry, we are out of ${winner}.`);
  }
};

function highestPropertyValue(obj) {
  const properties = Object.keys(obj);
  const max_value = Math.max(...Object.values(obj));
  const highest_properties = properties.filter(
    (prop) => obj[prop] === max_value
  );
  if (highest_properties.length === 1) {
    return highest_properties[0];
  } else {
    return highest_properties;
  }
}

function minPropertyValue(obj) {
  const properties = Object.keys(obj);
  const min_value = Math.min(...Object.values(obj));
  const min_properties = properties.filter((prop) => obj[prop] === min_value);
  if (min_properties.length === 1) {
    return min_properties[0];
  } else {
    return min_properties;
  }
}

function findMostRepeatedNumber(data) {
  const frequencyMap = {};

  // Count occurrences of each number
  for (const number in data) {
    if (data.hasOwnProperty(number)) {
      frequencyMap[data[number]] = (frequencyMap[data[number]] || 0) + 1;
    }
  }

  // Find the number with the highest frequency
  let mostRepeatedNumber = null;
  let highestFrequency = 0;
  for (const number in frequencyMap) {
    if (frequencyMap.hasOwnProperty(number) && frequencyMap[number] > highestFrequency) {
      mostRepeatedNumber = number;
      highestFrequency = frequencyMap[number];
    }
  }

  return mostRepeatedNumber;
}

export const determineWinner = (winnersList) => {
  let winnersResults = {};
  let finalWinner;
  let finalPoints;
  let finalLoser;
  let finalPointsLoser;
  for (let element in winnersList) {
    if (winnersResults[winnersList[element]] == null) {
      winnersResults[winnersList[element]] = determinePoints(element);
    } else {
      winnersResults[winnersList[element]] += determinePoints(element);
    }
  }
  const maxWinner = findMostRepeatedNumber(winnersList);
  winnersResults[maxWinner] = winnersResults[maxWinner] + 3;
  finalLoser = minPropertyValue(winnersResults);
  finalPointsLoser = Object.keys(winnersResults).length > 1 ? winnersResults[finalLoser] : 0;
  finalWinner = highestPropertyValue(winnersResults);
  finalPoints = winnersResults[finalWinner];
  return { finalWinner, finalPoints, finalLoser, finalPointsLoser };
};

export const calculatePlayersPoints = (
  sede,
  visitante,
  ganadores,
  walkover,
  idcategory
) => {
  let finalPoints = [];
  for (let item in ganadores) {
    if (ganadores[item] === sede.idequipo) {
      const newSedeValue = determinePlayersPoints(
        item,
        sede,
        walkover,
        idcategory
      );
      finalPoints.push(...newSedeValue);
    }
    if (ganadores[item] === visitante.idequipo) {
      const newVisitanteValue = determinePlayersPoints(
        item,
        visitante,
        walkover,
        idcategory
      );
      finalPoints.push(...newVisitanteValue);
    }
  }
  return finalPoints;
};

export function getCurrentFase() {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  return currentMonth > 7 ? `II-${currentYear}` : `I-${currentYear}`;
}

function generateSemestersSince2024() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const semesters = [];
  for (let year = 2024; year <= currentYear; year++) {
    semesters.push(`I-${year}`); 

    // Add the second semester if it has already passed in the current year
    if (year === currentYear && currentMonth > 7) { 
      semesters.push(`II-${year}`); 
    } else if (year < currentYear) { 
      semesters.push(`II-${year}`); 
    }
  }

  return semesters;
}

export const semesters = generateSemestersSince2024();