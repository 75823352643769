import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Button, Dropdown, Form, Input } from "semantic-ui-react";
import axios from "axios";
import imgAlineacion from "../../assets/dashboard/icono_vista_alineacion.svg";
import GradientButton from "../../components/GradientButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCurrentFase,
  getDayOfWeek,
  getPlayerCategoryInfo,
} from "../../helpers/commonFunctions";
import { MainContext } from "../../helpers/MainContext";
import Modal from "../../components/ModalPopUp/Modal";
import ModalMsg from "../../components/ModalPopUp/ModalMsg";
import "./alineacion.css";

const errorsInitialValue = {
  doble_11: false,
  doble_12: false,
  doble_21: false,
  doble_22: false,
  doble_31: false,
  doble_32: false,
  doble_41: false,
  doble_42: false,
  doble_51: false,
  doble_52: false,
}
const AgregarAlineacion = () => {
  const [alineacion, setAlineacion] = useState([]);
  const [allDisabled, setAllDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({ type: "", text: "" });
  const [errorList, setErrorList] = useState(errorsInitialValue);
  const [nameSede, setNameSede] = useState("");
  const [itsSede, setItsSede] = useState();
  const navigate = useNavigate();
  const { userTeamInfo, jornadasData } = useContext(MainContext);
  const [jugadores, setJugadores] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const { id: idJornada } = useParams();
  let jornadaData = jornadasData.find(
    (item) => item.idjornada === parseInt(idJornada, 10)
  );

  const playersSelected = [
    alineacion.doble_11,
    alineacion.doble_12,
    alineacion.doble_21,
    alineacion.doble_22,
    alineacion.doble_31,
    alineacion.doble_32,
    alineacion.doble_41,
    alineacion.doble_42,
    alineacion.doble_51,
    alineacion.doble_52,
  ];

  useEffect(() => {
    if (alineacion) {
      setSelectedPlayers([
        alineacion.doble_11,
        alineacion.doble_12,
        alineacion.doble_21,
        alineacion.doble_22,
        alineacion.doble_31,
        alineacion.doble_32,
        alineacion.doble_41,
        alineacion.doble_42,
        alineacion.doble_51,
        alineacion.doble_52,
      ]);
    }
  }, [alineacion]);

  function hasNoDuplicates(array) {
    const uniqueSet = new Set();
      for (const element of array) {
      if (uniqueSet.has(element)) {
        return false; 
      }
  
      uniqueSet.add(element);
    }
    return true;
  }

  const handleChange = (e, { name, value }) => {
    const data = value;
    if (selectedPlayers.includes(data)) {
      setErrorList({ ...errorList, [name]: true });
      setAlineacion({ ...alineacion, [name]: null });
      return;
    }
    setErrorList({ ...errorList, [name]: false });
    if(hasNoDuplicates([...selectedPlayers, data])){
      setErrorList(errorsInitialValue);
    }
    setSelectedPlayers([...selectedPlayers, data]);
    setAlineacion({ ...alineacion, [name]: data });
  };

  const getTeamById = useCallback(async (idTeam) => {
    await axios
      .get(`https://tennis-open-league-api.vercel.app/v1/teams/getteam/${idTeam}`)
      .then((res) => setNameSede(res.data?.name))
      .catch((error) => console.log(error));
  }, []);

  const getAlineacion = useCallback(async () => {
    await axios
      .get(
        `https://tennis-open-league-api.vercel.app/v1/alineaciones/${idJornada}/${userTeamInfo.id}`
      )
      .then((res) => setAlineacion(res.data))
      .catch((error) => console.log(error));
  }, [idJornada, userTeamInfo.id]);

  useEffect(() => {
    if (idJornada && userTeamInfo && userTeamInfo.id) getAlineacion();
  }, [idJornada, userTeamInfo, getAlineacion]);

  useEffect(() => {
    if (jornadaData) {
      getTeamById(jornadaData.idteamsede);
    }
  }, [jornadaData, getTeamById]);

  useEffect(() => {
    if (jornadaData?.idteamsede === userTeamInfo?.id) {
      setItsSede(true);
    }
    if (jornadaData?.idteamvisitante === userTeamInfo?.id) {
      setItsSede(false);
    }
  }, [jornadaData, userTeamInfo?.id]);

  const getPlayersData = useCallback(async () => {
    if (userTeamInfo.id !== undefined) {
      await axios
        .get(`https://tennis-open-league-api.vercel.app/v1/user/getusersbyteam/${userTeamInfo.id}`)
        .then((res) => setJugadores(res.data))
        .catch((error) => console.log(error));
    }
  }, [userTeamInfo.id]);

  useEffect(() => {
    if (userTeamInfo && userTeamInfo.id !== undefined) getPlayersData();
  }, [userTeamInfo, getPlayersData]);

  const confirmAlineacionJornada = async () => {
    const equipo = itsSede ? "sede" : "visitante";
    await axios
      .put(
        `https://tennis-open-league-api.vercel.app/v1/fasesjornadas/confirmar-alineacion/${idJornada}`,
        { equipo: equipo }
      )
      .then((res) => console.log("alineacion confirmada"))
      .catch((error) => console.log(error));
  };

  function hayErrores() {
    for (const propiedad in errorList) {
      if (errorList[propiedad] === true) {
        return true;
      }
    }
    return false;
  }

  const playersOptions =
    jugadores &&
    jugadores
      ?.map((item) => ({
        key: item.cedula,
        text: item.name,
        value: item.cedula,
      }))
      .sort((item1, item2) => {
        if (item1.text < item2.text) {
          return -1;
        } else if (item1.text > item2.text) {
          return 1;
        } else {
          return 0;
        }
      });

  const handleSave = async (e, confirmado) => {
    e.preventDefault();
    const payload = {
      ...alineacion,
      idjornada: idJornada,
      idequipo: userTeamInfo.id,
      confirmado: confirmado,
      idfase: getCurrentFase(),
      idcategory: jornadaData?.idcategory
    };
    await axios
      .post(`https://tennis-open-league-api.vercel.app/v1/alineaciones/create`, payload)
      .then((res) => {
        if (res.status === 200) {
          setModalInfo({
            type: "success",
            text: "Alineación cargada correctamente!",
          });
          if (confirmado) confirmAlineacionJornada();
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setModalInfo({
          type: "error",
          text: "Hubo un error al tratar de cargar la alineación",
        });
        setShow(true);
      });
  };

  return (
    <Box
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#03438a",
        minHeight: "100vh",
        fontFamily: "Montserrat",
        width: { md: "80%", xs: "80%" },
        alignItems: "center",
        borderRadius: "7px",
        border: "1px solid #ffffff",
        boxShadow: "4px 4px 4px #03435a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          backgroundColor: "#03438a",
          width: "100%",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            backgroundColor: "#03438a",
            width: "100%",
            borderRadius: "3px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              width: { md: "50%", xs: "100%" },
              textAlign: { md: "left", xs: "center" },
              placeContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { md: "40%", xs: "100%" },
                alignItems: "center",
                paddingTop: { md: 0, xs: "20px" },
                paddingLeft: { md: "10px", xs: 0 },
              }}
            >
              <img
                src={imgAlineacion}
                alt="Tennis Open League"
                style={{
                  width: window.innerWidth < 768 ? "100px" : "150px",
                  margin: "0 auto",
                  height: "150px",
                }}
              />
            </Box>
            <Box
              p={2}
              sx={{ width: { md: "60%", xs: "100%" }, color: "#ffffff" }}
            >
              <Typography
                sx={{
                  fontFamily: "MontserratBold",
                  fontSize: { md: "1.25rem", xs: "1rem" },
                  marginBottom: "15px",
                }}
              >
                Alineación
              </Typography>
              <Typography sx={{ fontFamily: "Montserrat" }}>
                Planilla de Sorteo
              </Typography>
              <Typography sx={{ fontFamily: "Montserrat" }}>
                {`Fase ${getCurrentFase()}`}
              </Typography>
            </Box>
          </Box>
          <Box
            p={2}
            sx={{
              display: "flex",
              width: { md: "50%", xs: "100%" },
              flexDirection: "column",
              alignItems: { md: "flex-end", xs: "center" },
              justifyContent: "flex-start",
            }}
          >
            <GradientButton
              text="VOLVER"
              typeButton="transparent"
              onClick={() => navigate("/dashboard/alineacion")}
              extraStyles={{
                marginTop: "30px",
                padding: "5px",
                marginRight: "3px",
              }}
            />
            <Button
              type="submit"
              disabled={alineacion?.confirmado || allDisabled}
              style={{
                backgroundColor: "transparent",
                border: "1px #ffff solid",
                padding: canEdit ? "7px 7px" : "7px 7px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
                marginTop: "10px",
              }}
              onClick={() => {
                setCanEdit((prevstate) => !prevstate);
              }}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                {canEdit ? "CANCELAR" : "EDITAR"}
              </p>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "80%", paddingTop: "70px" }}>
        <Box display="flex" flexDirection={{ md: "column", xs: "row" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-between",
            }}
          >
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "30%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              FASE:
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "30%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Categoria:
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "30%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Equipo:
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-between",
              marginTop: { md: "15px", xs: 0 },
            }}
          >
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "30%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: { md: "0", xs: "5px" },
              }}
            >
              {jornadaData?.tipojornada}
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "30%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: { md: "0", xs: "5px" },
              }}
            >
              {userTeamInfo?.category}
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "30%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {userTeamInfo?.name}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection={{ md: "column", xs: "row" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-between",
              marginTop: { md: "15px", xs: "5px" },
            }}
          >
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              SEM:
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Sede:
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Día:
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#ffa436",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Fecha:
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-between",
              marginTop: { md: "15px", xs: "5px" },
            }}
          >
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: { md: "0", xs: "5px" },
              }}
            >
              {jornadaData?.semana}
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {nameSede}
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: { md: "0", xs: "5px" },
              }}
            >
              {getDayOfWeek(jornadaData?.fecha)}
            </Typography>
            <Typography
              className="general-typography-classname"
              sx={{
                color: "#ffffff",
                width: { md: "20%", xs: "90%" },
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                backgroundColor: "#0082e5",
                borderRadius: "7px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: { md: "0", xs: "5px" },
              }}
            >
              {jornadaData?.fecha}
            </Typography>
          </Box>
        </Box>
        <Form className="contact-form-agregar-alineacion">
          <Box>
            <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
              DOBLE 1
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  width: { md: "30%", xs: "100%" },
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                Nombre y Apellido:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cédula:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cat:
              </Typography>
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_11"
                labeled
                search
                selection
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_11
                  )?.key || null
                }
                disabled={!canEdit}
                options={playersOptions}
                onChange={handleChange}
              />
              <Form.Field
                id="form-input-control-cedula-11"
                control={Input}
                name="cedula-11"
                readonly
                type="text"
                value={alineacion?.doble_11 || ""}
              />
              <Form.Field
                id="form-input-control-cat-11"
                control={Input}
                name="cat-11"
                type="text"
                readonly
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_11
                  )?.idcategory
                )}
              />
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_12"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_12
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-12"
                control={Input}
                name="hora"
                type="text"
                readonly
                value={alineacion?.doble_12}
              />
              <Form.Field
                id="form-input-control-cat-12"
                control={Input}
                name="hora"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_12
                  )?.idcategory
                )}
              />
            </Box>
            {(errorList?.doble_11 || errorList?.doble_12) && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontSize: "14px" }}
              >El jugador ya fue seleccionado!</Typography>
            )}
          </Box>
          <Box sx={{ paddingTop: "30px" }}>
            <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
              DOBLE 2
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  width: { md: "30%", xs: "100%" },
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                Nombre y Apellido:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cédula:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cat:
              </Typography>
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_21"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_21
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-21"
                control={Input}
                name="hora"
                type="text"
                value={alineacion?.doble_21}
              />
              <Form.Field
                id="form-input-control-cat-21"
                control={Input}
                name="hora"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_21
                  )?.idcategory
                )}
              />
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_22"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_22
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-22"
                control={Input}
                name="cedula-22"
                type="text"
                value={alineacion?.doble_22}
              />
              <Form.Field
                id="form-input-control-cat-22"
                control={Input}
                name="cat-22"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_22
                  )?.idcategory
                )}
              />
            </Box>
            {(errorList?.doble_21 || errorList?.doble_22) && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontSize: "14px" }}
              >El jugador ya fue seleccionado!</Typography>
            )}
          </Box>
          <Box sx={{ paddingTop: "30px" }}>
            <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
              DOBLE 3
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  width: { md: "30%", xs: "100%" },
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                Nombre y Apellido:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cédula:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cat:
              </Typography>
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_31"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_31
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-31"
                control={Input}
                name="cedula-31"
                type="text"
                value={alineacion?.doble_31}
              />
              <Form.Field
                id="form-input-control-cat-31"
                control={Input}
                name="cat-31"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_31
                  )?.idcategory
                )}
              />
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_32"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_32
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-32"
                control={Input}
                name="cedula-32"
                type="text"
                value={alineacion?.doble_32}
              />
              <Form.Field
                id="form-input-control-cat-32"
                control={Input}
                name="cat-32"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_32
                  )?.idcategory
                )}
              />
            </Box>
            {(errorList?.doble_31 || errorList?.doble_32) && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontSize: "14px" }}
              >El jugador ya fue seleccionado!</Typography>
            )}
          </Box>
          <Box sx={{ paddingTop: "30px" }}>
            <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
              DOBLE 4
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  width: { md: "30%", xs: "100%" },
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                Nombre y Apellido:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cédula:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cat:
              </Typography>
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_41"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_41
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-41"
                control={Input}
                name="cedula-41"
                type="text"
                value={alineacion?.doble_41}
              />
              <Form.Field
                id="form-input-control-cat-41"
                control={Input}
                name="cat-41"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_41
                  )?.idcategory
                )}
              />
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_42"
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_42
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-42"
                control={Input}
                name="cedula-42"
                type="text"
                value={alineacion?.doble_42}
              />
              <Form.Field
                id="form-input-control-cat-42"
                control={Input}
                name="cat-42"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_42
                  )?.idcategory
                )}
              />
            </Box>
            {(errorList?.doble_41 || errorList?.doble_42) && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontSize: "14px" }}
              >El jugador ya fue seleccionado!</Typography>
            )}
          </Box>
          <Box sx={{ paddingTop: "30px" }}>
            <Typography sx={{ textAlign: "left", color: "#ffffff" }}>
              DOBLE 5
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  width: { md: "30%", xs: "100%" },
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                Nombre y Apellido:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cédula:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  width: "30%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  backgroundColor: "#ffa436",
                  borderRadius: "7px",
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: "20px",
                  display: { md: "block", xs: "none" },
                }}
              >
                Cat:
              </Typography>
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_51"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_51
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-51"
                control={Input}
                name="cedula-51"
                type="text"
                value={alineacion?.doble_51}
              />
              <Form.Field
                id="form-input-control-cat-51"
                control={Input}
                name="cat-51"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_51
                  )?.idcategory
                )}
              />
              <Dropdown
                placeholder="Seleccione un jugador"
                name="doble_52"
                labeled
                search
                selection
                options={playersOptions}
                onChange={handleChange}
                value={
                  playersOptions.find(
                    (item) => item.key === alineacion?.doble_52
                  )?.key || null
                }
                disabled={!canEdit}
              />
              <Form.Field
                id="form-input-control-cedula-52"
                control={Input}
                name="cedula-52"
                type="text"
                value={alineacion?.doble_52}
              />
              <Form.Field
                id="form-input-control-cat-52"
                control={Input}
                name="cat-52"
                type="text"
                value={getPlayerCategoryInfo(
                  jugadores?.find(
                    (jugador) => jugador?.cedula === alineacion?.doble_52
                  )?.idcategory
                )}
              />
            </Box>
            {(errorList?.doble_51 || errorList?.doble_52) && (
              <Typography
                sx={{ textAlign: "center", color: "red", fontSize: "14px" }}
              >El jugador ya fue seleccionado!</Typography>
            )}
          </Box>
          <Box
            sx={{
              width: "100% !important",
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <Button
              type="submit"
              disabled={alineacion?.confirmado || allDisabled}
              style={{
                backgroundColor: "transparent",
                border: "1px #ffff solid",
                padding: canEdit ? "7px 20px" : "7px 32px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={() => {
                setCanEdit((prevstate) => !prevstate);
              }}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                {canEdit ? "CANCELAR" : "EDITAR"}
              </p>
            </Button>
            <Button
              type="submit"
              style={{
                backgroundColor: "#ffa436",
                border: "none",
                padding: "7px 20px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
                marginTop: window.innerWidth < 768 ? "20px" : 0,
              }}
              onClick={(e) => handleSave(e, false)}
              disabled={alineacion?.confirmado || allDisabled || hayErrores()}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                GUARDAR
              </p>
            </Button>
            <Button
              type="submit"
              style={{
                backgroundColor: "red",
                border: "none",
                padding: "7px 20px",
                boxShadow: "3px 3px 3px #03435a",
                cursor: "pointer",
                borderRadius: "5px",
                marginTop: window.innerWidth < 768 ? "20px" : 0,
              }}
              disabled={alineacion?.confirmado || allDisabled}
              onClick={(e) => {
                setAllDisabled(true);
                handleSave(e, true);
              }}
            >
              <p
                style={{
                  color: "#ffffff",
                }}
              >
                PUBLICAR
              </p>
            </Button>
          </Box>
        </Form>
        <Typography sx={{ marginTop: "30px", color: "#ffff" }}>
          NOTA IMPORTANTE: Cada capitán deberá tener la identificación vigente
          de cada uno de los jugadores inscritos en esta planilla a disposición
          del otro Capitán.
        </Typography>
      </Box>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <ModalMsg typeModal={modalInfo.type} textModal={modalInfo.text} />
      </Modal>
    </Box>
  );
};

export default AgregarAlineacion;
